.header{
    background: #1D1616;
    padding: 15px 0px;
}
.mobileLogo{
    font-size: 20px;
    margin-left: 25px;
    font-weight: bold;
    top: 3px;
    position: relative;
    text-decoration: none;
}
.mobileLogo:hover{
    color: #FFF;
    text-decoration: none;
}
.menuGrid{
    display: grid;
    color: #FFF;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr;
}
.menuGrid a{
    color: #FFF;
    text-decoration: none;
    margin: 0px;
}
.menuGrid a:hover{
    color: var(--color-one);
}

.sarchInputWarpper{
    width: 400px;
    background: #312525;
    border-radius: 100px;
    border: none;
    padding: 6px 5px 6px 20px;
    display: grid;
    grid-template-columns: 1fr 38px;
}
.sarchInputWarpper button{
    background: transparent;
    border:none;
    filter: invert();
}
.searchInput{
   background: transparent;
   border:none;
   width: 100%;
   outline: none;
   color: #FFF;
}
.sarchInputWarpper img{
    width: 100%;
    filter: invert();
}

.magnifyGlass{background: transparent; }
.downloadBtn{background-color: var(--color-one); border-radius: 50px;  border:none; padding: 6px 25px; color: #FFF; transition: ease-in-out .2s;}
.downloadBtn:hover{background-color: var(--color-two); transition: ease-in-out .2s;}


@media only screen and (max-width :600px) {
    .sarchInputWarpper{width: 100% !important;}
 }


.mobileList{text-align: center;}
#MenuBox{ background: #1D1616; padding: 15px 15px;}




.desktopLogo{
    width: 50px;
}
.mobileLogo{
    width: 80px;
}