@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
body{font-family: 'Montserrat', sans-serif;}
:root {
  --color-one: #F90610;
  --color-two : #bd0000;
 }
 a{text-decoration: none;}
 a:hover{text-decoration: none;}
 @media screen and (min-width: 1920px) { .container { max-width: 1440px !important; } }
 .btn{ border:  1.5px solid #FFF; border-radius: 40px; background: transparent; color: #FFF; width: 170px; height: 50px;}
 /*.page{ background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%); padding: 50px 0px; }*/
 .page{
      /* background-image: linear-gradient(93.58deg,rgba(60, 1, 4, 0.3),rgba(25, 1, 2, .9) ), url(./assets/pagebg.jpeg); */
      background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%);
      background-size: contain;
    background-position: center center;
    background-attachment: fixed;
    padding: 50px 0px;
    min-height: 60vh;
 }


 .joinnowBtn{width: 100%;
    height: 40px;
   background: transparent;
    border: 1px solid #FFF;
    border-radius: 0px; margin-top: 10px;
    border-radius: 4px;color: #FFF;}  
  .joinnowBtn:hover{color: #FFF;  background: var(--color-two);}





  .stickName .circular-glow-in-ref {
    width: 92px;
    height: 92px;
    top: -13.5px;
    right: 7px;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(45deg, transparent, transparent 40%, yellow),
      linear-gradient(#262626, #141414);
    animation: rotate 1.5s linear infinite;
  }
  .stickName .circular-glow-in-ref:before,
  .stickName .circular-glow-in-ref:after {
    content: " ";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    background: linear-gradient(#161616, #262626);
    border-radius: inherit;
  }
  .stickName .circular-glow-in-ref:before {
    background: linear-gradient(
      45deg,
      transparent,
      transparent 40%,
      yellow
    );
    filter: blur(32px);
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
      filter: hue-rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    .stickName .circular-glow-in-ref {
      width: 59px;
      height: 59px;
      top: -12px;
      right: 7.3px;
      position: absolute;
      border-radius: 50%;
      background: linear-gradient(
          45deg,
          transparent,
          transparent 40%,
          yellow
        ),
        linear-gradient(#262626, #141414);
      animation: rotate 1.5s linear infinite;
    }
  }
  /* right bounce */
  @-webkit-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -webkit-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -webkit-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  @-moz-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-30px);
    }
    60% {
      transform: translateX(-15px);
    }
  }
  @keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -ms-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  