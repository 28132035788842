/*.gameDetail{
    background: radial-gradient(361.73% 1644.58% at 100% 100%, #271B01 0%, #161108 45.16%, #2D281D 100%);
    color: var(--color-three);
}
.gameDetailGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:15px
}*/
.gridContent{
    line-height: 27px;
    color: #C99C9C;
}
.gamedetailSlider{
    width: 100%!important;
}
/*@media only screen and (max-width : 460px) {
    .gameDetail{
        padding: 50px 15px;
        color: var(--color-three);
    }
    .gameDetailGrid{
        
        grid-template-columns: 1fr;
    }
}*/


.gameDetailSection{ padding: 50px 0px; background: linear-gradient(156.38deg, #3C0104 26.34%, #230103 95.91%);}
.gameDetail .rec-carousel-wrapper {
    display: block;
    margin:auto;
}
.gameDetail .rec-arrow-left{ 
    margin-top:0px;     left: 130px;}

    .gameDetail .rec-arrow-right{ right: 53px; margin-top: 0px;    }
@media only screen and (max-width : 600px) {
.gameDetail .rec-arrow-left{z-index: 999;    width: 40px;
    height: 40px;
    min-width: 40px;
    line-height: 40px; margin-top: 0px; left: 0px;}

    .gameDetail .rec-arrow-right{
        z-index: 999;    width: 40px;
        height: 40px;
        min-width: 40px;
        line-height: 40px;margin-top: 0px; right: 0px;
    }
}
.gameDetailHeading{
    font-size: 27px;
    color: #FFF;
    margin-top: 0px;

}
.gameDetailBox{
    
background: linear-gradient(90deg, #262626 0%, #404040 100%);
box-shadow: 20px 0px 30px rgba(37, 5, 82, 0.2);
border-radius: 20px;
padding: 40px;
margin-top: 30px;
}
@media only screen and (max-width : 600px) {
    .gameDetailBox{
        padding: 0px ;
        background: transparent;
    }
}
.GameDataImage{
border-radius: 6px;
    width: 100%;
}

.imagePopup{
    cursor: pointer;
    transition: ease-in-out .2s;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    border-radius: 8px;
    margin-bottom: 30px;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.gameDetailSlider .recommendedgames{
    background: transparent !important;
}
/*
.gameDetailImg{
    background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.5) 0%, rgba(26, 26, 26, 0.5) 45.16%, rgba(51, 51, 51, 0.5) 100%), url(../../assets/individual-games-3840.jpg);
}*/




.gameDetaildownloadBtn{
    border:1px solid #FFF;
    width: 100%;
    background:transparent;
    text-align: center;
    padding:10px 0px;
    border-radius: 8px;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #FFF;
}
.gameDetaildownloadBtn:hover{
    background: var(--color-one);
    color: #FFF
}



.gamedetailBox{
    background: linear-gradient(156.38deg, #640207 15.22%, #320104 84.78%);
border-radius: 6px;
padding:15px;
}
.gamedetailleftSpan{
    color: #C99C9C;
}
.gamedetailRightSpan{
    color: #FFF;
    text-align: right;
}
.gamedetailRightSpan img{
    margin-left: 10px;
    width: 20px;
}


.gamedetailTwoCol{
    margin-bottom: 10px;
}



.similarGameSection{
    background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%);
    padding: 50px 0px;
}
.similarTitle{
    color: #FFF;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 30px;
}
.similargamesBox{
    background: linear-gradient(180deg, #3B0205 1.87%, #280103 100%);
    border: 1.5px solid #FBF4F4;    
    border-radius: 5px;
    margin-bottom: 25px;
}
.similargamesRightBox{
    padding: 25px;
}
.similargamesRightBoxTitle{
    color: #FFF;

}
.similargamesRightBoxTitle span{
    font-size: 18px;
    position: relative;
    left: 30px;
}
.similargamesRightBoxDescription{
    color: #C99C9C;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.gameBoxdownloadBtn{
    border: 1.5px solid #FBF4F4;   
    padding: 8px 15px;
    background-color: transparent;
    color: #FFF;
    border-radius: 5px;
    float: right;
}
.gameBoxdownloadBtn:hover{
    background: var(--color-one);
}
.gameboxBottom{
    border-top: 1px solid #fbf4f4;
    padding: 20px 0px
}

@media only screen and (max-width : 600px) {
    .similargamesRightBoxDescription{display: none;}
    .similargamesRightBox{padding: 15px 15px;}
    .similargamesRightBoxTitle{margin-bottom: 0px;}
    .gameboxBottom{padding: 4px 0px;}
}










.verticalSlider{
    height: 1000px;
}
@media only screen and (max-width : 600px) {
    .verticalSlider {
        height: 304px;
    }
}



.detail .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .detail .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .detail .mySwiper2 {
    height: auto !important;
    width: 100%;
  }
  
  .detail .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .detail .mySwiper .swiper-slide {
    
    height: 83px !important;
    /* opacity: 0.4; */
  }
  
  .detail .mySwiper .swiper-slide-thumb-active {
    /* opacity: 1; */
    border:2px solid var(--color-one)
  }
  
  .detail .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  


  .detail  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }

  .thumb  .swiper-slide img {
    display: block;
    width: 100%;
   
    height: 79px;

    object-fit: cover !important;
  }


.homepageLatestCardContent h4{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}