.pageBox{
    background: linear-gradient(156.38deg, #640207 15.22%, #320104 84.78%);;
    padding: 30px;
    border-radius: 8px;
    color: #FFF;
}


/*.termsBoxTwo{
    background: linear-gradient(90deg, #404040 0%, #595959 100%);
    border-radius: 10px;
    padding: 15px;
}

.termHeading{
    color: #FFF !important;
    text-transform: uppercase;
    line-height: 34px;
}
.marginTop15{
    margin-top: 15px;
}


.aboutbg{
    
        background-image: linear-gradient(93.58deg,rgba(60, 1, 4, 0.3),rgba(25, 1, 2, .9) ), url(../../assets/pagebg.jpeg);
        background-position: center center;
    
}*/