.bannerSection{
    background: linear-gradient(156.38deg, #3C0104 26.34%, #230103 95.91%);
    color: #FFF;
}
.bannerHeading{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 44px;
}


.bannerBtn{margin-top: 18px;}
.bannerBtn:hover{color: #FFF; background: var(--color-one);}

.swiper-button-prev, .swiper-button-next{
    background: rgba(0, 0, 0, 0.7);
    color: #FFF;
    padding: 30px;
    border-radius: 50px;
}
.bannerSection .swiper-button-next{
    right: 27%;
}
.swiper-button-prev:hover, .swiper-button-next:hover{
    background: var(--color-one);
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 23px;
}



@media only screen and (max-width :600px) {
    .bannerSection .swiper-button-next{ right: 10px; }
    .videowarpper{padding: 0px !important;}
    .videoGameTitle, .VideoGameShowMore{text-align: center; display: block; margin: auto;}
    .swiper-button-prev, .swiper-button-next{padding: 22px;}
    .bannerHeading{text-align: center; font-size: 20px;}
    .bannerBtn{margin: auto; display: block; margin-bottom: 15px;}
    .bannerType{text-align: center; margin-top: 15px; margin-bottom: 0px;}
}