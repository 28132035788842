.footer{background: #190102; color: #FFF; padding: 50px 0px;}
ul{padding: 0px; list-style: none;}
a{color: #FFF; margin-bottom: 10px; display: block;}
a:hover{color: #FFF;}
.footer h3{ font-style: normal; font-weight: bold; font-size: 18px; color: #FFF; line-height: 22px; margin-bottom: 30px;}
.socialMedia{display: flex;}
.socialMedia a{margin-right: 15px;}
.copyrightFooter{background: #0F0001; color: #FFF; padding:15px 0px}
.copyrightFooter p{padding: 0px  !important; margin: 0px  !important;}
.footerLogo{margin-bottom: 15px;}

