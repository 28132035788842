body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace;
}








.stickName{
  background-color: #000;
  color: yellow;
  width: auto;
  position: fixed;
  right: 0px;
  top: 50%;
  z-index: 99999;
  padding: 22px 105px  22px 22px;
    border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  font-family: 'Bangers', cursive;
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}
.stickImage{
  position: absolute;
  width: 86px;
    top: -8px;
    right: 10px;
    background: #000;
    border-radius: 50%;
    padding: 13px;
    border: 3px solid rgba(255,255,255,.1);
}
@media only screen and (max-width : 768px) {
  .stickName{
    padding: 10px 80px 10px 15px;
    font-size: 0.8em;    top: 81%;
  }
  .stickImage {
    width: 54px;
    padding: 7px;
}
 
}