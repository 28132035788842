.fourofour{
    padding-bottom: 100px;
    background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.9) 0%, rgba(26, 26, 26, 0.9) 45.16%, rgba(51, 51, 51, 0.9) 100%);
}


.fourofourHeading{
    font-size: 100px;
    color: #FFF;
    text-align: center;
    margin-bottom: 0px;
}
.fourofourtext{
    color: #FFF;
    text-align: center;
}

.fourofourLink{
    padding: 10px 15px;
    color: #FFF;
    text-align: center;
    border: 2px solid #FFF;
    text-align: center;
    display: block;
    width: 100px;
    margin: auto;
    margin-top: 40px;
}
