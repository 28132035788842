.faqPage{
    background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.9) 0%, rgba(26, 26, 26, 0.9) 45.16%, rgba(51, 51, 51, 0.9) 100%);  
}
.faqPageImg{
    background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.5) 0%, rgba(26, 26, 26, 0.5) 45.16%, rgba(51, 51, 51, 0.5) 100%), url(../../assets/faq-1920X1080.jpg);
}



.react-tabs__tab-list{
    background: linear-gradient(156.38deg, #550206 15.22%, #4B0206 84.78%);
    border-radius: 5px;
    border-bottom:none;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    color: var(--color-four);
    width: 24%;
    color: #FFF;
    margin-left: 38%;
}
@media only screen and (max-width : 1025px) {
    .react-tabs__tab-list{
        width: 95%;
        margin-left: 0%;
    }
    .accordion{
        width: 100% !important;
    }
}
.react-tabs__tab{
    border:none
}
.react-tabs__tab--selected{
    background: var(--gradient-one);
    border-radius: 5px !important;
    color: #C99C9C;
}


.accordion{
    width: 50%;
    display: block;
    margin: auto;
}
@media only screen and (max-width : 768px) {
    .faqPage{
        background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.9) 0%, rgba(26, 26, 26, 0.9) 45.16%, rgba(51, 51, 51, 0.9) 100%);
    }
    .accordion{
        width: 100% !important;
        display: block;
        margin: auto;
    }
}
.accordion__button{
    background: linear-gradient(156.38deg, #550206 15.22%, #4B0206 84.78%);
    border-radius: 5px;
    color: #FFF !important;
    margin-top: 15px;
    width: initial !important;
}
.accordion__panel{
    background: linear-gradient(156.38deg, #550206 15.22%, #4B0206 84.78%);
    border-radius: 5px;
    color: #FFF;
    font-size: 15px !important;
    line-height: 26px;
}

